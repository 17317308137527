import Navigation from "./Nav-bar";
import NavButtons from "./NavButtons";
import VideoWithText from "./VideoWithText";
import LatestProductsHeading from "./LatestProducts";
import About from "./AboutInLandingPage";
import QuotedParagraph from "./LandingPageQuates";
import Footers from "./Footers";
import FootIcons from "./FootIcons";
export default function App() {
  return (
    <>
      <div>
        <Navigation />
        <NavButtons />
      </div>
      <div>
        <VideoWithText />
      </div>
      <div>
        <LatestProductsHeading/>
      </div>
      <About />
      <QuotedParagraph/>
      <Footers />
      <FootIcons/>
    </>
  );
}

