import { useState } from "react";



export default function NavButtons() {
    const [isHovered, setIsHovered] = useState({
      home: false,
      product: false,
      company: false,
      contact: false
    });
  
    const buttonStyle = {
      border: "none",
      backgroundColor: "white",
      cursor: 'pointer',
      position: 'relative', // Add relative positioning
      textDecoration: 'none', // Remove default underline
      padding: '10px 20px', // Add padding
    };
  
    const underlineStyle = {
      content: '',
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      height: '5px', // Set the underline height
      backgroundColor: '#93CEEF', // Set the underline color
      display: 'none', // Initially hide the underline
    };
  
    const handleMouseEnter = (button) => {
      setIsHovered(prevState => ({ ...prevState, [button]: true }));
    };
  
    const handleMouseLeave = (button) => {
      setIsHovered(prevState => ({ ...prevState, [button]: false }));
  };
  
    const handleClick = () => {
      alert('Update soon');
  };
  
    return (
      <div className="btn-group">
        <div className="nav-btn-left">
          <button 
            style={buttonStyle} 
            onMouseEnter={() => handleMouseEnter('home')} 
            onMouseLeave={() => handleMouseLeave('home')}
          >
            HOME
            <span style={{ ...underlineStyle, display: isHovered.home ? 'block' : 'none'}}></span>
          </button>
          <button 
            style={buttonStyle} 
            onMouseEnter={() => handleMouseEnter('product')} 
            onMouseLeave={() => handleMouseLeave('product')}
            onClick={handleClick}
          >
            PRODUCT
            <span style={{ ...underlineStyle, display: isHovered.product ? 'block' : 'none'}}></span>
          </button>
          <button 
            style={buttonStyle} 
            onMouseEnter={() => handleMouseEnter('company')} 
            onMouseLeave={() => handleMouseLeave('company')}
            onClick={handleClick}
          >
            COMPANY
            <span style={{ ...underlineStyle, display: isHovered.company ? 'block' : 'none'}}></span>
          </button>
        </div>
        <div>
          <button 
            style={buttonStyle} 
            onMouseEnter={() => handleMouseEnter('contact')} 
            onMouseLeave={() => handleMouseLeave('contact')}
            onClick={handleClick}
          >
            CONTACT US
            <span style={{ ...underlineStyle, display: isHovered.contact ? 'block' : 'none'}}></span>
          </button>
        </div>
      </div>
    );
  }