// import React from "react";
// const Products = [
//   {
//     title :'HDPE',
//     ProductImage: {src:"/Products/TWINES1.jpg", alt:'HDPE'}
//   }
// ]

// export default function LatestProducts() {
//   const HeadingStyle = {
//     fontFamily: "julius sans one",
//     paddingLeft: "5%",
//   };
//   return (
//     <>
//       <div>
//         <h1 style={HeadingStyle}>LATEST PRODUCTS</h1>
//       </div>
      
//       {Products.map((product, index) => (
//         <div key={index} className="card">
//           <img src={product.ProductImage.src} alt={product.ProductImage.alt} className="card-image" />
//           <div className="card-title">{product.title}</div>
//         </div>
//       ))}
//     </>
//   );
// }


// import React from "react";

// const Products = [
//   {
//     title: 'Monofilament Webbing',
//     ProductImage: { src: "/Products/TWINES1.jpg", alt: 'HDPE' }
//   },
//   {
//     title: 'Multifilament Webbing',
//     ProductImage: { src: "/Products/HDPE.jpg", alt: 'LDPE' }
//   },
//   {
//     title: 'HDPE Webbing',
//     ProductImage: { src: "/Products/HDPE1.jpg", alt: 'PP' }
//   },
//   {
//     title: 'Twines',
//     ProductImage: { src: "/Products/monofilament-fishing.webp", alt: 'PVC' }
//   }
// ];

// export default function LatestProducts() {
//   const HeadingStyle = {
//     fontFamily: "julius sans one",
//     paddingLeft: "5%",
//   };
  
//   const gridContainerStyle = {
//     display: "grid",
//     gridTemplateColumns: "repeat(2, 1fr)",
//     gap: "20px",
//     padding: "20px",
//   };

//   const cardStyle = {
//     position: "relative", // Add this to enable absolute positioning of title and overlay
//     border: "1px solid #ccc",
//     borderRadius: "8px",
//     overflow: "hidden",
//     textAlign: "center",
//     fontFamily: "julius sans one",
//   };

//   const imageContainerStyle = {
//     position: "relative",
//   };

//   const cardImageStyle = {
//     width: "100%",
//     height: "auto",
//     display: "block",
//   };

//   const overlayStyle = {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: "100%",
//     backgroundColor: "rgba(0, 0, 0, 0.5)",
//   };

//   const cardTitleStyle = {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)", // Center the title
//     color: "white",
//     fontSize: "24px",
//     fontWeight: "bold",
//     textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
//   };

//   const buttonStyle = {
//     display: "block",
//     margin: "20px auto",
//     marginLeft:"18rem",
//     padding: "10px 20px",
//     fontSize: "16px",
//     cursor: "pointer",
//     backgroundColor: "#93ceef",
//     color: "#fff",
//     border: "none",
//     borderRadius: "0px 20px 0px 20px",
//   };

 
//   return (
//     <>
//       <div>
//         <h1 style={HeadingStyle}>LATEST PRODUCTS</h1>
//       </div>
      
//       <div style={gridContainerStyle}>
//         {Products.map((product, index) => (
//           <div key={index} style={cardStyle} className="card">
//             <div style={imageContainerStyle}>
//               <img src={product.ProductImage.src} alt={product.ProductImage.alt} style={cardImageStyle} className="card-image" />
//               <div style={overlayStyle}></div>
//               <div style={cardTitleStyle} className="card-title">{product.title}</div>
//             </div>
//           </div>
//         ))}
//       </div>
      
//       <button style={buttonStyle}>See More</button>
      
//     </>
//   );
// }


import React from "react";

const Products = [
  {
    title: 'Monofilament Webbing',
    ProductImage: { src: "/Products/TWINES1.jpg", alt: 'HDPE' }
  },
  {
    title: 'Multifilament Webbing',
    ProductImage: { src: "/Products/HDPE.jpg", alt: 'LDPE' }
  },
  {
    title: 'HDPE Webbing',
    ProductImage: { src: "/Products/HDPE1.jpg", alt: 'PP' }
  },
  {
    title: 'Twines',
    ProductImage: { src: "/Products/monofilament-fishing.webp", alt: 'PVC' }
  }
];

export default function LatestProducts() {
  const HeadingStyle = {
    fontFamily: "julius sans one",
    paddingLeft: "5%",
  };
  
  const gridContainerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
    gap: "20px",
    padding: "20px",
  };

  const cardStyle = {
    position: "relative", // Add this to enable absolute positioning of title and overlay
    border: "1px solid #ccc",
    borderRadius: "8px",
    overflow: "hidden",
    textAlign: "center",
    fontFamily: "julius sans one",
    height:'180px'
  };

  const imageContainerStyle = {
    position: "relative",
  };

  const cardImageStyle = {
    width: "100%",
    height: "auto",
    display: "block",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  };

  const cardTitleStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)", // Center the title
    color: "white",
    fontSize: "24px",
    fontWeight: "bold",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
  };

  const buttonStyle = {
    display: "block",
    margin: "20px auto",
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    backgroundColor: "#93ceef",
    color: "#fff",
    border: "none",
    borderRadius: "0px 20px 0px 20px",
  };

  return (
    <>
      <div>
        <h1 style={HeadingStyle}>LATEST PRODUCTS</h1>
      </div>
      
      <div style={gridContainerStyle}>
        {Products.map((product, index) => (
          <div key={index} style={cardStyle} className="card">
            <div style={imageContainerStyle}>
              <img src={product.ProductImage.src} alt={product.ProductImage.alt} style={cardImageStyle} className="card-image" />
              <div style={overlayStyle}></div>
              <div style={cardTitleStyle} className="card-title">{product.title}</div>
            </div>
          </div>
        ))}
      </div>
      
      <button style={buttonStyle}>See More</button>
      
    </>
  );
}
