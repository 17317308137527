// import "./index.css";

// const navItems = [
//   {
//     BrandFirstName: "QUALITY ",
//     BrandSecondName: "FISH NETS",
//     logoName: "logo.jpg",
//     icons: [
//       { src: "icons/phone.png", alt: "phoneIcon" },
//       { src: "icons/mail.png", alt: "mail" },
//       { src: "icons/whatsapp.png", alt: "whatsapp" },
//     ],
//   },
// ];

// export default function Navigation() {
//   const Items = navItems[0];

//   return (
//     <>
//       <div className="navItems">
//         <div className="right-side">
//           <div>
//             <img className="logo" src={Items.logoName} alt={Items.BrandName} />
//           </div>
//           <div className="BrandName">
//             <h3 className="brandName">
//               {Items.BrandFirstName}
//             </h3>
//             <h5 className="brandSecondName" style={{ paddingLeft: "28px", marginTop: "5px" }}>
//               {Items.BrandSecondName}
//             </h5>
//           </div>
//         </div>

//         <div>
//           {Items.icons.map((icon, index) => (
//             <img key={index} className="icon" src={icon.src} alt={icon.alt} />
//           ))}
//         </div>
//       </div>
//       <hr className="custom-hr" />
//     </>
//   );
// }

import "./index.css";

const navItems = [
  {
    BrandFirstName: "QUALITY ",
    BrandSecondName: "FISH NETS",
    logoName: "logo.jpg",
    icons: [
      { src: "icons/phone.png", alt: "phoneIcon" },
      {
        src: "icons/mail.png",
        alt: "mail",
        href: "mailto:your-email@example.com",
      },
      {
        src: "icons/whatsapp.png",
        alt: "whatsapp",
        href: "https://wa.me/918891347237?text=Hello%20Quality%20Fish%20Nets",
      }, // Add WhatsApp link here
    ],
  },
];

export default function Navigation() {
  const Items = navItems[0];

  return (
    <>
      <div className="navItems">
        <div className="right-side">
          <div>
            <img className="logo" src={Items.logoName} alt={Items.BrandName} />
          </div>
          <div className="BrandName">
            <h3 className="brandName">{Items.BrandFirstName}</h3>
            <h5
              className="brandSecondName"
              style={{ paddingLeft: "28px", marginTop: "5px" }}
            >
              {Items.BrandSecondName}
            </h5>
          </div>
        </div>

        <div>
          {Items.icons.map((icon, index) =>
            icon.alt === "mail" ? (
              <a key={index} href={icon.href}>
                <img className="icon" src={icon.src} alt={icon.alt} />
              </a>
            ) : icon.alt === "whatsapp" ? (
              <a
                key={index}
                href={icon.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="icon" src={icon.src} alt={icon.alt} />
              </a>
            ) : (
              <img key={index} className="icon" src={icon.src} alt={icon.alt} />
            )
          )}
        </div>
      </div>
      <hr className="custom-hr" />
    </>
  );
}
