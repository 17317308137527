import React, { useEffect, useRef } from 'react';

export default function VideoWithText() {
  const videoRef = useRef(null);

  useEffect(() => {
    // Function to attempt to play the video
    const playVideo = () => {
      if (videoRef.current) {
        videoRef.current.play().catch((error) => {
          console.error('Video playback error:', error);
        });
      }
    };

    // Attempt to play the video when the component mounts
    playVideo();

    // Add event listeners to handle user interactions as a fallback
    const handleUserInteraction = () => {
      playVideo();
    };

    document.addEventListener('click', handleUserInteraction);
    document.addEventListener('touchstart', handleUserInteraction);

    // Clean up event listeners on component unmount
    return () => {
      document.removeEventListener('click', handleUserInteraction);
      document.removeEventListener('touchstart', handleUserInteraction);
    };
  }, []);

  const landingPageVideo = {
    src: "video/landingPage.mp4",
    alt: "video",
  };

  const VideoStyle = {
    position: "relative",
    width: "100%",
    height: "434px",
    overflow: "hidden",
    marginTop: "1rem",
  };

  const VideoLoop = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "relative",
    zIndex: -1,
  };

  const textDivStyle = {
    position: "absolute",
    top: "50%",
    left: "20%",
    transform: "translate(-50%, -50%)",
    textAlign: "left",
    color: "white",
  };

  const HeadingStyle = {
    fontSize: "32px",
    marginBottom: "20px",
    paddingLeft: '10rem',
    fontFamily: 'Atkinson Hyperlegible, sans-serif'
  };

  const paragraphStyle = {
    fontSize: "20px",
    paddingLeft: '10rem',
    fontFamily: 'Atkinson Hyperlegible, sans-serif',
    fontWeight: '100'
  };

  return (
    <div style={VideoStyle}>
      <video 
        ref={videoRef}
        autoPlay 
        loop 
        muted 
        playsInline 
        preload="auto" 
        style={VideoLoop}
        onError={(e) => console.error('Video playback error:', e)}
      >
        <source src={landingPageVideo.src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div style={textDivStyle}>
        <h1 className="videoText" style={HeadingStyle}>FISHERIES</h1>
        <p style={paragraphStyle}>
          Providing best in class solutions to help our fisherman minimize <br />
          the costs and maximize their catch.
        </p>
      </div>
    </div>
  );
}
