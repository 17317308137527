import React from "react";
import "./FootIcons.css";

// Component for rendering social media icons in the footer
const FootIcons = () => {
  return (
    <div className="social-icons">
      <div>
        {/* Facebook icon */}
        <img src="/FooterIcon/facebook.png" alt="Facebook" className="icon" />
      </div>
      <div>
        {/* Twitter icon */}
        <img src="/FooterIcon/twitter.png" alt="Twitter" className="icon" />
      </div>
      <div>
        {/* Instagram icon */}
        <img src="/FooterIcon/instagram.png" alt="Instagram" className="icon" />
      </div>
      <div>
        {/* Whatsapp icon */}
        <img src="/FooterIcon/youtube.png" alt="youtube" className="icon" />
      </div>
    </div>
  );
};

export default FootIcons;
