// import React from 'react';
// import { FaQuoteLeft } from 'react-icons/fa';
// import "./About.css"

// const text =`"We have associated with QUALITY for the past 10 years plus. The
// reason we keep working with QUALITY is consistency, quality of 
// product and innovation. We hope to represent them for the next 10 
// years and more Customer Views on Our Fishing Nets | QUALITY FISH NETS"`



// export default function QuotedParagraph ()  {
//   return (
//     <div>
//           <FaQuoteLeft className="quote-icon" color='#93ceef' style={{ fontSize: '120px' }} />
//           <p>{text}</p>
//           <FaQuoteLeft className="quote-icon" color='#93ceef'  style={{fontSize:'120px', transform: 'scaleX(-1)' }} />
//     </div>
//   );
// };

import React from 'react';
import { FaQuoteLeft } from 'react-icons/fa';
import "./QuotedParagraph.css";

const text = `We have associated with QUALITY for the past 10 years plus. The
reason we keep working with QUALITY is consistency, quality of 
product and innovation. We hope to represent them for the next 10 
years and more `;

const text2 = 'Customer Views on Our Fishing Nets | QUALITY FISH NETS';

export default function QuotedParagraph() {
  return (
    <div className="quoted-paragraph-container">
      <div className="quote">
        <FaQuoteLeft className="quote-icon" color='#93ceef'/>
              <p className="quote-text">{text}</p>
              <p className="quote-text">{text2}</p>
        <FaQuoteLeft className="quote-icon inverted" color='#93ceef' />
      </div>
    </div>
  );
};

