import React from "react";
import "./Foot.css"


export default function Footers() {
  return (
    <>
     <div className="footer">
      <div className="container">
        <div className="footer-content">
          <p className="footer-text">Stay updated with our latest news and offers!</p>
          <div className="subscription-form">
            <input type="email" className="email-input" placeholder="Enter your email" />
            <button type="submit" className="subscribe-button">Subscribe</button>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};



