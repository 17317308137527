import "./About.css";

export default function About() {
  return (
    <>
      <hr className="custom-hr" />
      <br></br>
      <div className="about-container">
        <div className="content-container">
          <h2 className="title">QUALITY FISH NETS</h2>
          <p className="description">
            We are leading manufacturers of Nylon Multi filament Fishing Nets
            and Fishnet Twines. We are in Fishnet Business for the last 4
            decades, with state-of-the-art manufacturing facilities of Fishing
            nets and Fishnet Twines of all kinds. We are also into trading of
            Nylon Yarns and other fishing requisites and accessories. Currently,
            our business spans across Indian, African & Middle-east markets.
            Please feel free to view our product line to find out more about how
            we can best serve your needs. For more information or to request a
            quote, please visit our sales page to contact a member of our sales
            team.
          </p>
          <p className="description">
            Custom Manufactured Commercial Fishing Nets: We have been
            manufacturing custom made commercial fishing nets since 1980 and
            trading accessories for fishing and industrial applications. We have
            Skilled Personnel and a strong Infrastructure for making Fishnets
            built to your custom specifications. We can fabricate nets in any
            shape or size to meet your specific needs. We are able to offer you
            quality nets at competitive pricing by manufacturing nets using the
            best materials and offering them direct to you. We stock a large
            selection of netting and supplies, which allows us to custom build
            and ship your net quickly. We have the best quality control and cost
            control thus our price would be the lowest with highest quality. Our
            main business is OEM production for a lot of manufacturers or
            wholesalers all over the world who are dealing in fishing nets. Our
            main market is in Africa, Southeast Asia, Middle East and other
            global markets.
          </p>
          <h3>Why buy from QUALITY FISH NETS? </h3>
          <p className="description">
            It has good infrastructure and skilled manpower to deliver orders on
            time every time. With the state-of-the-art Dyeing Machine, always
            colour perfection is achieved. The Yarn which we use is of High
            Tenacity and so the life of the nets is very good. The Length and
            depth of the nets are maintained accurately as per the Customer
            requirements. CFN also specializes in Polyethylene Ropes and Twines.
            Virgin Quality Prime Grade Raw material is used to maintain quality
            and good strength in HDPE Ropes.
          </p>
        </div>
      </div>
      <h2 className="solutions">
        Solutions that make fishing more profitable every single day
      </h2>
      <br></br>
      <hr className="custom-hr" />
    </>
  );
}
